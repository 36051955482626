/*
 * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
 * product_right.php, wishlist_index.php
 */

var button_add_to_wishlist_initial_click_event;

/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist() {

    var btn = $(this);
    var idElt = btn.data('idelt');
    var form = btn.parents('form#prod_info_' + idElt);

    if (btn.parent().parent().attr('id') == "lightboxAchatExpressV1Brand") {
        var productId = this.getAttribute("data-produitid");
        var sizeId = $("#lightboxAchatExpressV1 input[name='itm_size_" + productId + "']:checked").val();
        var colorId = $("#lightboxAchatExpressV1 input[name='itm_color_" + productId + "']:checked").val();
        var currentPage = 0;
    } else if (typeof form !== "undefined" && form.length) {
        var productId = form.find('[name="produit_id"]').val();
        var colorId = form.find('[name="couleurProd"]').val();
        var sizeId = form.find('[name="tailleProd"]').val();
        var currentPage = 0;
    } else {
        var productId = btn.data('productid');
        var colorId = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
        var sizeId = $('#tailleProd_' + idElt).length == 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();
        var currentPage = $('#page').val();
    }

    var wishlist = [];

    let datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'true',
        need_size_id: 'true'
    };

    if (!btn.hasClass('existToWishlistButton')) {
        $.ajax({
            url: path_relative_root + "ajax_add_to_wishlist.php",
            type: 'post',
            data: datas
        })
            .done(function (resp) {
                var $alertboxLogin = $("#abox");
                var $alertboxBtns = $("#abox .wrap_btn_abox");
                $alertboxLogin.addClass('box_wishlist');

                if (resp.error) {
                    var message = resp.result.error_message ? resp.result.error_message : resp.message;
                    if (resp.message === "login_required") {
                        var wishlistSubmit = $('.form_submit_wishlist', $alertboxLogin);
                        if (wishlistSubmit.length === 0) {
                            $alertboxBtns
                                .append($(
                                    '<div class="form_submit form_submit_wishlist">' +
                                    '<button class="button primary"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + productId + '\', \'' + currentPage + '\');">' +
                                    '<span>' +
                                    resp.result.btn_connexion +
                                    '</span>' +
                                    '</button>' +
                                    '</div>')
                                );
                        } else {
                            wishlistSubmit.css('display', 'flex');
                        }
                        alert('<div class="box_txt_wishlist">' + resp.result.error_message + '</div>');
                    } else if (resp.message != "error") {
                        $(".disabledBtnTooltip").addClass('blink');
                        setTimeout(function() {
                            $(".disabledBtnTooltip").removeClass('blink');
                        }, 2000);

                        // if the button is inside the div #lightboxAchatExpressV1, display the error message in the lightbox below .size_select_wrapper
                        if (btn.parent().parent().attr('id') == "lightboxAchatExpressV1Brand") {
                            var errorDiv = btn.parent().parent().parent().find('.size_select_wrapper');
                            console.log("errorDiv", errorDiv);
                            errorDiv.append('<div class="error">' + Translator.translate('choose_size') + '</div>');
                            setTimeout(function() {
                                errorDiv.find('.error').remove();
                            }, 3500);
                        }
                    } else {
                        alert(Translator.translate(message));
                    }
                } else {
                    btn.addClass("existToWishlistButton");
                    btn.data('wishlistproductid', resp.result.wishlist_product_id);
                    btn.attr('data-wishlistproductid', resp.result.wishlist_product_id);
                    if (typeof (wishlist) == 'undefined') {
                        wishlist = new Array();
                    }
                    wishlist.push(resp.result.wishlist_row_array);
                    addToWishlistUpdateHeaderCount(resp.result.wishlist_size);

                    /*Recharge la page pour le slider wishlist*/
                    if ($('body.cart.step_1').length) {
                        window.location.reload();
                    }

                    let dl_v3_obj = resp.result.dlv3_wishlist_item;
                    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && Object.keys(dl_v3_obj).length > 0) {
                        DataLayerTrigger.addToWishList(dl_v3_obj['items'], dl_v3_obj['actionField'])
                    }
                }
            });
    } else {
        datas.remove = true;

        $.ajax({
            url: path_relative_root + "ajax_remove_from_wishlist.php",
            type: 'post',
            data: datas
        })
            .done(function (resp) {
                if (!resp.error) {
                    btn.removeClass("existToWishlistButton");
                    btn.data('wishlistproductid', '');
                    btn.attr('data-wishlistproductid', '');
                    addToWishlistUpdateHeaderCount(resp.result.wishlistLength);

                    /*Recharge la page pour le slider wishlist*/
                    if ($('body.cart.step_1').length) {
                        window.location.reload();
                    }
                }
            });
    }
}

/**
 * Permet d'ajour un produit à la wishlist via le rayon (ou la recherche)
 * Utilisation : app/_components/product/achat_express.php/achat_express_search.php
 */
function addToWishlistRay() {

    // On utilise le même que dans la fiche produit car cela fait la même chose
    // mais on donne un nom différent au cas où
    addToWishlist.call(this);
}

/**
 *
 * @param productId
 * @param itm_rr_id
 * @returns {boolean}
 */
function checkProductInWishlist(productId, itm_rr_id) {
    let productElem = itm_rr_id ? productId + itm_rr_id : productId;
    let btnWishlist = productId && !itm_rr_id ? 'addToWishlistButton_' + productId : itm_rr_id ? 'addToWishlistButton_' + productId + itm_rr_id : 'addToWishlistButton';

    let colorId = $("#couleurProd_" + productElem).length ? $("#couleurProd_" + productElem).val() : $("#couleurProd").val();
    let sizeId = $("#tailleProd_" + productElem).length ? $("#tailleProd_" + productElem).val() : $("#tailleProd").val();

    if (colorId === undefined || colorId === '') {
        colorId = 0;
    }

    if (sizeId === undefined || sizeId === '') {
        sizeId = 0;
    }

    if (typeof productId != "undefined" && typeof colorId != "undefined" && typeof sizeId != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data: {'product_id': productId, 'size_id': sizeId, 'color_id': colorId},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true, btnWishlist);
                    return true;
                } else {
                    changeBtnAddWishlist(false, btnWishlist);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false, btnWishlist);
        return false;
    }
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 */
function changeBtnAddWishlist(in_wishlist, btn = false) {

    let btnWishlist = (btn !== false ? btn : 'addToWishlistButton');

    if (in_wishlist) {
        $("#" + btnWishlist).attr("href", "javascript:;");
        $("span", "#" + btnWishlist).html(Translator.translate("exist_to_wishlist_button_label"));
        $("#" + btnWishlist).removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        $("#" + btnWishlist).attr("href", "javascript:;");
        $("span", "#" + btnWishlist).html(Translator.translate("wishlist"));
        $("#" + btnWishlist).removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

function wishlistRedirectLogin(div_id, currentPage) {
    $.get(path_relative_root + "ajax_add_to_wishlist.php?login_required=1&div_id=" + div_id + "&currentPage=" + currentPage, function () {
        document.location.href = path_relative_root + create_link('connexion_login') + "?from=front&div_id=" + div_id;
    });
}

function addToWishlistUpdateHeaderCount(nr) {

    if (!document.body.classList.contains('mobile')) {
        if (nr === 0) {
            $("#topLogin .wishlist_cnt").addClass('empty');

        } else {

            $("#topLogin .wishlist_cnt").html('(' + nr + ')').removeClass('empty');
        }

        var response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
        $("#wishlist_top").html(response_html);
    }
}

function addProductToCart(product_id, color_id, size_id) {
    $.post(path_relative_root + create_link('ajax_wishlist_add_product_to_cart'),
        {
            product_id: product_id,
            color_id: color_id,
            size_id: size_id
        }, function (resp) {
            if (resp === "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
            } else {
                alert(Translator.translate('error_add_basket'));
            }
        });
}

function addCustomizedProductToCart(pc_id) {
    var errors = [];

    //get data
    var customizationData = {};
    $.ajax({
        url: path_relative_root + create_link('ajax_configurator_get_configuration'),
        type: 'post',
        async: false,
        data: {pc_id: pc_id},
        dataType: 'json',
        success: function (response) {
            if (response.success) {
                customizationData = response.json;
            }
        }
    });

    // add main product to basket
    if (customizationData.steps.length) {
        //send main product
        customizationData.product.configurator_product = false;
        customizationData.product.reinit_basket_virtuel_parent_id = true;
        customizationData.product.set_session_basket_as_parent = true;
        var response = sendProductToBasket(customizationData.product);
        // if main product added, add other products
        if (response.error !== undefined && response.error !== 0) {
            if (response.error.msg !== '' && response.error.msg !== undefined) {
                alert(Translator.translate('error_insert_basket') + '<br>' + (errors.join('<br>')));
            }
        } else {
            $.each(customizationData.steps, function (index, customizationData) {
                $.each(customizationData.customization, function (indexProduct, product) {
                    product.id = product.product_id;
                    product.configurator_product = true;
                    var response = sendProductToBasket(product);
                    // On regarde si il y a eu une erreur dans l'ajout (cas plus de stock)
                    if (response.error !== undefined) {
                        if (response.error.msg !== '' && response.error.msg !== undefined) {
                            errors.push(response.error.msg);
                        }
                    }
                });
            });

            if (errors.length) {
                alert(Translator.translate('error_insert_basket') + '<br>' + (errors.join('<br>')));
            } else {
                //update produit_configurator_data
                $.ajax({
                    url: path_relative_root + create_link('ajax_configurator_update_virtual_basket_id'),
                    type: 'post',
                    async: false,
                    data: {
                        pc_id: pc_id
                    },
                    dataType: 'json',
                    success: function (response) {
                        if (response.success) {
                            document.location.href = path_relative_root + create_link('order_basket');
                        } else {
                            alert(Translator.translate('error_add_basket'));
                        }
                    }
                });
            }
        }
    }
}

function sendProductToBasket(product) {
    var response = {};
    $.ajax({
        url: path_relative_root + "ajax_upd_panier.php",
        type: "post",
        dataType: 'json',
        async: false,
        data: {
            configurator: true,
            idprod: product.id,
            idcouleur: product.color_id,
            idtaille: product.size_id,
            panierid: 0,
            basket_virtuel_parent_id: product.basket_virtuel_parent_id,
            qte: 1,
            configurator_product: product.configurator_product,
            dataTypeReturn: 'json',
            reinit_basket_virtuel_parent_id: product.reinit_basket_virtuel_parent_id != undefined ? product.reinit_basket_virtuel_parent_id : false,
            set_session_basket_as_parent: product.set_session_basket_as_parent != undefined ? product.set_session_basket_as_parent : false,
        },
        success: function (res) {
            response = res;
        }
    });
    return response;
}

function showSendWishlistModal() {

    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + "ajax_send_wishlist.php",
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}

function closeSendWishlistLightBox() {
    var backBtn = $("#content_sendwishlistbox > .w-form-line.w-submit button");
    if (backBtn.length) { 
        backBtn.click()
    } else {
        closeMultiShad('sendwishlistbox')
    }
}

function addAllWpToCart()
{
    var i = 0;
    var wishlistProductId = "";
    var wp = $(".addToCartFromWishlist");
    for (i = 0; i < wp.length; i++) {
        wishlistProductId += "," + wp[i].getAttribute('data-id');
    }
    addWpToCart(wishlistProductId);
}

function addWpToCart(wishlistProductId) {
    if (typeof wishlistProductId != "string") {
        wishlistProductId = wishlistProductId.toString();
    }

    var selectedProducts = wishlistProductId.split(',');

    $.post(path_relative_root + "ajax_add_panier_wishlist.php", {data: wishlistProductId}, function(resp) {
        var data_layer_products = window.data_layer_products;
        var dtl_products = [];
        if (resp == "ok") {
            if (Object.keys(data_layer_products).length > 0) {
                for (const property in data_layer_products) {
                    if (selectedProducts.indexOf(property) !== -1) {
                        dtl_products.push(data_layer_products[property])
                    }
                }
            }

            if (dtl_products.length > 0) {
                var dataLayer = window.dataLayer || []
                dataLayer.push({
                    "event": "addToCart",
                    "ecommerce": {
                        'currencyCode' : 'window.siteCurrency',
                        "add": {
                            "products": dtl_products
                        }
                    }
                })

            }

            document.location.href = path_relative_root + create_link('order_basket');
        } else {
            $('.lightbox .form_submit .button span').html(Translator.translate("close"));
        }
    });
}

/**
 * Envoie le formulaire de la lightbox wishlist avec gestion des erreurs
 * Utilisation : wishlist_index.php
 */
function sendWishListForm(form) {

    var friendMail = form.elements['friend_email'].value;
    var inputMailElm = $(form.elements['friend_email']);

    if (friendMail.length > 0) {

        inputMailElm.removeClass('inputErr');
        var data = {
            'to_mails': friendMail,
            'to_message': 'Wishlist',
            'your_name': form.elements['customer_firstname'].value,
            'your_mail': form.elements['customer_email'].value
        };

        var error = false;
        var reportMessage = '';
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist_form'),
            data: data
        }).done(function (response) {

            $('.zone_text').css({border: ''});

            if (response.split(',')[0] === 'ok') {
                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
                    dataLayer.push({
                        "event": "sendWish_click",
                    });
                }
                var sing = (response.split(',').length > 2) ? 'sendfriend_ok_plur' : 'sendfriend_ok_sing';
                reportMessage = Translator.translate(sing);
            }
            if (response === '1' || response === '2') {
                error = true;
                reportMessage = Translator.translate('sendfriend_error_mail_friends_invalid');
            } else if (response === '3') {
                error = true;
                reportMessage = Translator.translate('sendfriend_error_no_message');
            } else if (response === '4') {
                error = true;
                reportMessage = Translator.translate('sendfriend_error_no_name');
            } else if (response === '5' || response === '6') {
                error = true;
                if (response === '5') {
                    reportMessage = Translator.translate('sendfriend_error_no_my_mail');
                } else {
                    reportMessage = Translator.translate('sendfriend_error_my_mail_invalid');
                }
            } else if (response === 'token_error') {
                error = true;
                reportMessage = Translator.translate('error_token_security');
            } else if (response.split(',')[0] === '7') {
                error = true;
                reportMessage = Translator.translate(sing);
            }

            var emailReportElm = $('.email_wishlist_report');
            emailReportElm.html(reportMessage);
            if (error) {
                inputMailElm.parent('label').addClass('w-has-error');
                inputMailElm.addClass('inputErr');
                emailReportElm.addClass('error');
            } else {
                emailReportElm.removeClass('error');
                inputMailElm.val('');
            }
            emailReportElm.removeClass('cache');
        });

    } else {
        inputMailElm.parent('label').addClass('w-has-error');
        inputMailElm.addClass('inputErr');
    }

    return false;
}