// This example displays an address form, using the autocomplete feature
// of the Google Places API to help users fill in the information.

var placeSearch;

function initialize_global() {

	var componentForm = {
	  street_number: {target : '#adresseLiv', length : 'short_name'},
	  route: {target : '#adresseLiv', length : 'long_name'},
	  locality: {target : '#ville', length : 'long_name'},
	  country: {target : '#pays', length : 'short_name'},
	  postal_code: {target : '#codeP', length : 'short_name'}
	};
	// Create the autocomplete object, restricting the search
	// to geographical location types.
	var inputAutocompleteId = 'autocomplete_addr';
	var inputAutocompleteIdJq = '#'+inputAutocompleteId;
	var searchElem = document.getElementById(inputAutocompleteId);
	var option = {
		types: ['geocode']
		//,componentRestrictions: { country: [<?php echo $liste_pays_iso; ?>] }
	};

	var autocomplete = new google.maps.places.Autocomplete( (searchElem), option);
	// When the user selects an address from the dropdown,
	// populate the address fields in the form.
	google.maps.event.addListener(autocomplete, 'place_changed', function(e) {
		fillInAddress(autocomplete, componentForm, true,inputAutocompleteIdJq,'.noaddr', '.elem_addr_autocomplete', 'select#pays');
		showGlobal(inputAutocompleteIdJq,'.noaddr','.elem_global', 'finish', '.elem_addr_autocomplete');
		$(inputAutocompleteIdJq).trigger('place_changed');
	});
	$(searchElem).on('blur', function(e) {
		fillInAddress(autocomplete, componentForm, true,inputAutocompleteIdJq,'.noaddr', '.elem_addr_autocomplete', 'select#pays');
	});

	commonAction(searchElem, autocomplete );

	$('.wrapper_form_adresse').on({
		'click':function(){
			$('.elem_addr_autocomplete').advanceSlide('show');
			$('.noaddr').css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
				$('.noaddr').css({display : 'none',opacity : ''});
			});
		}
	},'.noaddr');
	$(inputAutocompleteIdJq).on('keyup focusout blur',function(e){
		showGlobal(e.target,'.noaddr','.elem_global', '', '.elem_addr_autocomplete');
	});
}

function initialize_delivery() {

	var componentDelivery = {
	  street_number: {target : '#adresseLiv', length : 'short_name'},
	  route: {target : '#adresseLiv', length : 'long_name'},
	  locality: {target : '#ville', length : 'long_name'},
	  country: {target : '#pays', length : 'short_name'},
	  postal_code: {target : '#codeP', length : 'short_name'}
	};

	if ($('#adresseLiv').length == 0) {
		var componentDelivery = {
		  street_number: {target : 'input.adresseLiv', length : 'short_name'},
		  route: {target : 'input.adresseLiv', length : 'long_name'},
		  locality: {target : 'input.ville', length : 'long_name'},
		  country: {target : 'select.pays', length : 'short_name'},
		  postal_code: {target : 'input.codeP', length : 'short_name'}
		};
	}

	// Create the autocomplete object, restricting the search
	// to geographical location types.
	var searchElem = document.getElementById('autocomplete_addr');
	var option = {
		types: ['geocode']
		//,componentRestrictions: { country: [<?php echo $liste_pays_iso; ?>] }
	};

	var autocompleteDelivery = new google.maps.places.Autocomplete( (searchElem), option);
	// When the user selects an address from the dropdown,
	// populate the address fields in the form.
	google.maps.event.addListener(autocompleteDelivery, 'place_changed', function(e) {
		fillInAddress(autocompleteDelivery, componentDelivery, true,'#autocomplete_addr','.noaddr.delivery', '.elem_addr_autocomplete.delivery', 'select#pays');
		showGlobal('#autocomplete_addr','.noaddr.delivery','.elem_global.delivery', 'finish', '.elem_addr_autocomplete.delivery');
		$('#autocomplete_addr').trigger('place_changed');
	});
	$(searchElem).on('blur', function(e) {
		fillInAddress(autocompleteDelivery, componentDelivery, true,'#autocomplete_addr','.noaddr.delivery', '.elem_addr_autocomplete.delivery', 'select#pays');
	});

	commonAction(searchElem, autocompleteDelivery );

	$('.wrapper_form_adresse').on({
		'click':function(){
			$('.elem_addr_autocomplete.delivery').advanceSlide('show');
			$('.noaddr.delivery').css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
				$('.noaddr.delivery').css({display : 'none',opacity : ''});
			});
		}
	},'.noaddr.delivery');
	$('#autocomplete_addr').on('keyup focusout blur',function(e){
		showGlobal(e.target,'.noaddr.delivery','.elem_global.delivery', '', '.elem_addr_autocomplete.delivery');
	});
}


function initialize_bill() {
	var componentBill = {
	  street_number: {target : '#billadresseLiv', length : 'short_name'},
	  route: {target : '#billadresseLiv', length : 'long_name'},
	  locality: {target : '#billville', length : 'long_name'},
	  country: {target : '#billpays', length : 'short_name'},
	  postal_code: {target : '#billcodeP', length : 'short_name'}
	};
	// Create the autocomplete object, restricting the search
	// to geographical location types.
	var searchElem = document.getElementById('billautocomplete_addr');
	var option = {
		types: ['geocode']
		//,componentRestrictions: { country: [<?php echo $liste_pays_iso; ?>] }
	};

	var autocompleteBill = new google.maps.places.Autocomplete( (searchElem), option);
	// When the user selects an address from the dropdown,
	// populate the address fields in the form.
	google.maps.event.addListener(autocompleteBill, 'place_changed', function(e) {
		fillInAddress(autocompleteBill, componentBill, true,'#billautocomplete_addr','.noaddr.bill', '.elem_addr_autocomplete.bill', 'select#billpays');
		showGlobal('#billautocomplete_addr','.noaddr.bill','.elem_global.bill', 'finish', '.elem_addr_autocomplete.bill');
		$('#billautocomplete_addr').trigger('place_changed');
	});
	$(searchElem).on('blur', function(e) {
		fillInAddress(autocompleteBill, componentBill, true,'#billautocomplete_addr','.noaddr.bill', '.elem_addr_autocomplete.bill', 'select#billpays');
	});

	commonAction(searchElem, autocompleteBill );

	$('.wrapper_form_adresse').on({
		'click':function(){
			$('.elem_addr_autocomplete.bill').advanceSlide('show');
			$('.noaddr.bill').css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
					$('.noaddr.bill').css({display : 'none',opacity : ''});
				});
		}
	},'.noaddr.bill');
	$('#billautocomplete_addr').on('keyup focusout blur',function(e){
		showGlobal(e.target,'.noaddr.bill','.elem_global.bill', '', '.elem_addr_autocomplete.bill');
	});
}


function initialize_map(type) {
	var componentForm_map = {
	  street_number: {target : '.choix_relais_relais'+type+' #street', length : 'short_name'},
	  route: {target : '.choix_relais_relais'+type+' #street', length : 'long_name'},
	  locality: {target : '.choix_relais_relais'+type+' #city', length : 'long_name'},
	  country: {target : '.choix_relais_relais'+type+' #country', length : 'short_name'},
	  postal_code: {target : '.choix_relais_relais'+type+' #zip', length : 'short_name'}
	};
	// Create the autocomplete object, restricting the search
	// to geographical location types.
	var searchElem = $(".choix_relais_relais" + type + " .autocomplete_addr_map" )[0];
	var option = {
	types: ['geocode']
	//,componentRestrictions: { country: [<?php echo $liste_pays_iso; ?>] }
	};

	var autocomplete_map = new google.maps.places.Autocomplete( (searchElem), option);
	// When the user selects an address from the dropdown,
	// populate the address fields in the form.
	google.maps.event.addListener(autocomplete_map, 'place_changed', function(e) {
		fillInAddress(autocomplete_map, componentForm_map, false, '.choix_relais_relais'+type+' .autocomplete_addr_map', '', '', '#zip');
		$('.choix_relais_relais'+type+' .autocomplete_addr_map').trigger('place_changed');
		var address = $('.choix_relais_relais'+type+' #street').val();
		var zipcode = $('.choix_relais_relais'+type+' #zip').val();
		var city = $('.choix_relais_relais'+type+' #city').val();
		var country = $('.choix_relais_relais'+type+' #country').val();
		if( zipcode != '' ){
			loadMapDelivery(address+'|'+zipcode+'|'+city+'|'+country, type);
		}
	});

	commonAction(searchElem, autocomplete_map );
}

function commonAction(searchElem, autcompleteElem ){
	$(searchElem).on({
		'focus':function(){
			geolocate(autcompleteElem);
		}
	});
}

function getKeys(obj) {

	var keys, key;

	arrayKeys = [];

	for (key in obj) {

		if (obj.hasOwnProperty(key)) {

			arrayKeys.push(key);
		}
	}

	return arrayKeys;
}

// [START region_fillform]
function fillInAddress(resultGoogle, listComponent, activAnime, adrId , noadr, adrDetai, verifTarget) {
// Get the place details from the autocomplete object.
	var place = resultGoogle.getPlace();
	if(typeof place == 'undefined'){
		$(adrDetai).advanceSlide('show');
		return false;
	}
	var componentLng = place.address_components.length;
	var finalResult = {};
	var getShortCodeCountry;
	var displayed = false;
	var listComponentToArray = getKeys(listComponent);
	var listComponentLength = listComponentToArray.length;
	var listeTypeResult = {};
	var listeElemResult = {};
	var showAdrDetai = false;

	for(i=0; i < componentLng; i++){
		var addressType = place.address_components[i].types[0];
		listeTypeResult[addressType] = i;
		if (listComponent[addressType]) {
			var adressValue = place.address_components[i][listComponent[addressType].length];
			listeElemResult[addressType] = adressValue;
			if(addressType == 'country'){
				getShortCodeCountry = adressValue;
			}
			var valTarget = listComponent[addressType].target;
			if(finalResult[valTarget] != undefined){
				finalResult[valTarget] = finalResult[valTarget] +' '+ adressValue;
			}else{
				finalResult[valTarget] = adressValue;
			}
		}
	}
	if (listeTypeResult.postal_code == undefined && listeTypeResult.administrative_area_level_2 != undefined) {
		finalResult[listComponent.postal_code.target] = place.address_components[listeTypeResult.administrative_area_level_2][listComponent.postal_code.length];
	}


	for(l=0; l < listComponentLength; l++){
		var target = listComponentToArray[l];
		var targetList = listComponent[target].target;


		if(finalResult[targetList] == undefined){
			finalResult[targetList] = '';
		}
		if(listeElemResult[target] == undefined){
			showAdrDetai = true;
			$(adrDetai).advanceSlide('show');
		}
	}
	var is_dom = ( $.inArray( getShortCodeCountry,['GP','GF','MQ','RE','YT']) != -1 );
	var is_tom = ( $.inArray( getShortCodeCountry,['PM','MF','BL','WF','TF','NC','PF']) != -1 );

	getShortCodeCountry = (is_dom) ? 'DO' : (is_tom) ? 'TO' : getShortCodeCountry;

	var array_corres_code_valide = { BE: 'B', LU: 'L', MC: 'FR', CH: 'S', FR: 'FR', DO :'DO', TO : 'TO'};

	if(array_corres_code_valide[getShortCodeCountry] != undefined){
		getShortCodeCountry = array_corres_code_valide[getShortCodeCountry];
	}

	var finalArray = getKeys(finalResult);
	var finalLng = finalArray.length;
	for(j = 0; j < finalLng; j++){
		if(activAnime && verifTarget != undefined){
			if( $(finalArray[j]).is(verifTarget)){
				if(verifTarget.indexOf('pays') > 0){
					$(adrId).data('google-val',getShortCodeCountry);
					if($('option[data-name='+getShortCodeCountry+']',finalArray[j]).length){
						$('option',finalArray[j]).prop('selected',null);
						$('option[data-name='+getShortCodeCountry+']',finalArray[j]).prop('selected','selected').trigger('change');
					}else{
						$('option',finalArray[j]).prop('selected',null);
						$('option[data-name="null"]',finalArray[j]).prop('selected','selected').trigger('change');
						if(displayed == false){
							displayed = true;
							$(noadr).css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
								$(noadr).css({display : 'none',opacity : ''});
							});
							setTimeout(function(){
								$(adrDetai).advanceSlide('show');
								$('input', adrDetai).trigger('blur');
							},1000);
						}
					}
				}
			}else{
				$(finalArray[j]).val(finalResult[finalArray[j]]).trigger('blur');
			}
		}else{
			$(finalArray[j]).val(finalResult[finalArray[j]]);
		}

	}
	if(verifTarget != undefined){
		if(verifTarget.indexOf('pays') > 0){
			$(adrId).data('google-val',getShortCodeCountry);
		}
		if(verifTarget.indexOf('zip') > 0 && $(adrId).length > 0){
			$(adrId).data('google-val',getShortCodeCountry+'|'+$(verifTarget).val());
		}
	}

	if(activAnime){
		var elemLength = getKeys(listComponent).length;
		for(k = 0; k < elemLength; k++ ){
			var currentTarget = listComponent[getKeys(listComponent)[k]].target;

			if($.inArray( currentTarget, finalArray ) == -1 && displayed == false){
				displayed = true;
				$(noadr).css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
					$(noadr).css({display : 'none',opacity : ''});
				});
				setTimeout(function(){
					$(adrDetai).advanceSlide('show');
					$('input', adrDetai).trigger('blur');
				},1000);
			}
		}
	}
}
// [END region_fillform]


// [START region_geolocation]
// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function geolocate(resultGoogle) {
  if (navigator.geolocation && navigator.userAgent.indexOf('Firefox') == -1) {
	navigator.geolocation.getCurrentPosition(function(position) {
	  var geolocation = new google.maps.LatLng(
		  position.coords.latitude, position.coords.longitude);
		  resultGoogle.setBounds(
			new google.maps.LatLngBounds(geolocation, geolocation)
		  );
	});
  }
}
// [END region_geolocation]


function showGlobal(target, noadr, elemGlob, addrExist, adrDetai){
	var elemGoole = $('.pac-item','.pac-container');
	var resultLength = $('.pac-item','.pac-container').length;
	if($(target).prop('value').length >= 2 || elemGoole.length ){
		$(elemGlob).advanceSlide('show');
		if(!$(noadr).is('.show') && addrExist != 'finish' && !$(adrDetai).is(':visible')){
			$(noadr).addClass('show').css({display : 'block', opacity : 0}).animate({opacity: 1},function(){
				$(noadr).css({opacity : ''});
			});
		}
		if(addrExist == 'finish'){
			$(noadr).removeClass('show').stop().css({display : 'block', opacity : 1}).animate({opacity: 0},function(){
				$(noadr).css({display : 'none',opacity : ''});
			});
		}
	}
}
