function faqinit(array_questions) {
    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {
            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each( array_questions, function ( i, val ) {



                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if ( match ) {
                    array_match.push(match);
                    $('.search_results').show();
                    $( 'li#brand_' + val[ 'id' ] ).addClass( "hasMatch" );
                    $('#theme_global .theme').removeClass("active");
                    $('#theme_global .theme:nth-child(' + val[ 'theme_id' ] + ')').addClass("active");
                }
            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }
    
        } else {		
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });
    
    var click_in_process = false;

    $('.search_results').mousedown(function() {
        click_in_process = true;
    });

    $('.search_results').mouseup(function() {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function() {
        if(!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function() {
        $('body.faq').addClass('actif');
        $('#theme_global .theme.active').removeClass('active');
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }        
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        theme.addClass('actif');
        showTabFaq(id, 1);
    });

    $('#theme_page .theme').on('click', function() {
        var id = $(this).data('id');
        showTabFaq(id, 1);
    });
}

function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        //show answer
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp(
            "fast",
            function() {
                $(this).addClass("cache");
            }
        );

        $(".faq_reponse_" + question_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass(
            "active"
        );
        $(".faq_question_" + question_id).toggleClass("active");
    } else {
        var parent_id = $(".faq_theme_" + node_id).data("parent");

        //active
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");
        $(".faq_theme_" + parent_id).toggleClass("active");

        //show questions
        $(
            ".faq_questions:not(.faq_questions_" +
            node_id +
            ", .faq_questions_" +
            parent_id +
            " )"
        ).slideUp("fast", function() {
            $(this).addClass("cache");
        });

        $(".faq_questions_" + node_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        // Hide other node of same level
        $(".faq_level_" + level + ":not(.faq_theme_" + node_id + ")").each(
            function(idx) {
                var id = $(this).data("id");
                $(".faq_level_parent_" + id).slideUp("fast", function() {
                    $(".faq_level_parent_" + id)
                        .addClass("cache")
                        .trigger("classChange");
                });
            }
        );

        // Show/hide child of selected node
        $(".faq_level_parent_" + node_id).slideToggle("slow", function() {
            $(".faq_level_parent_" + node_id)
                .toggleClass("cache")
                .trigger("classChange");
        });

        $(".faq_element").on("classChange", function() {
            var id = $(this).data("id");

            if ($(this).hasClass("cache")) {
                $(".faq_level_parent_" + id)
                    .addClass("cache")
                    .trigger("classChange");
            }
        });
    }
}

function researchFaq(node_id, level, question_id) { 
    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({scrollTop: $(".faq_question_" + question_id + "").offset().top - 100}, 1000);
}

$(document).ready(function() {
    $('#theme_global .theme:first-child').addClass('active');
    $('#theme_page .theme_detail .faq_element:first-child').addClass('active');
    $('#theme_page .theme_detail .faq_element:first-child .faq_questions').show().removeClass('cache');
});